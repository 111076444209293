export default class TableControleUploadPontoModel {
  constructor({ controleUploadId, codigoPonto, status, erro, detalhe } = {}) {
    this._controleUploadId = controleUploadId;
    this._codigoPonto = codigoPonto;
    this._status = status;
    this._erro = erro;
    this._detalhe = detalhe;
  }

  get controleUploadId() {
    return this._controleUploadId;
  }

  set controleUploadId(controleUploadId) {
    return (this._controleUploadId = controleUploadId);
  }

  get codigoPonto() {
    return this._codigoPonto;
  }

  set codigoPonto(codigoPonto) {
    return (this._codigoPonto = codigoPonto);
  }

  get status() {
    return this._status;
  }

  set status(status) {
    return (this._status = status);
  }

  get erro() {
    return this._erro;
  }

  set erro(erro) {
    return (this._erro = erro);
  }

  get detalhe() {
    return this._detalhe;
  }

  set detalhe(detalhe) {
    return (this._detalhe = detalhe);
  }
}
