<template>
  <div>
    <modal-base width="590px" @closeModal="close">
      <template #header> Sincronizar Informações </template>
      <template #body>
        <div class="body-modal">
          <p class="text-red">
            Deseja prosseguir com a sincronização? <br />
            <span class="text-black">
              Essa ação irá reiniciar o faturamento deste contrato. Cálculos e
              propostas de curto prazo ou recompra serão perdidos. Caso a
              empresa esteja configurada para receber demonstrativo de forma
              automática, um novo demonstrativo poderá ser enviado ao cliente.
              Deseja continuar?
            </span>
          </p>
        </div>
      </template>
      <template #footer>
        <div class="container-buttons">
          <button class="btn btn-primary-outlined cancel-btn" @click="close">
            Cancelar
          </button>
          <button
            class="btn btn-primary-full-filled proceed-btn"
            @click="proceed"
          >
            Sim, prosseguir
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  methods: {
    close() {
      this.$emit("close");
    },
    proceed() {
      this.$emit("sincronizarDivergenciasWbc");
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.body-modal {
  text-align: center;
  margin: 20px 16px;
}

.text-red {
  color: #d40d00;
  font-weight: 700;
}

.text-black {
  color: #2a2a2a;
  font-weight: 500;
}

.container-buttons {
  width: 100%;
  display: flex;
  gap: 22px;
  align-items: center;
  justify-content: center;
}

.cancel-btn {
  border-radius: 10px !important;
}

.proceed-btn {
  background: #d40d00 !important;
  border: 1px solid #d40d00 !important;

  &:hover {
    color: #d40d00 !important;
    background: #fff !important;
  }
}
</style>
