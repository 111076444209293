<template>
  <div>
    <modal-base
      v-if="showModalContract"
      :maxWidth="'80%'"
      @closeModal="
        () => {
          showModalContract = false;
          pontosTable = [];
        }
      "
      data-cy="modal-fechar_modal_contratos_pro_infa-pos-venda-modais"
    >
      <template #header>
        Contratos Associados ao Ponto {{ pontosTable.codigo }}
      </template>
      <template #body>
        <div class="body_contratos_associados">
          <data-table
            :fields="CONTRATO_TABLE_FIELDS"
            :data="pontosTable.contratos"
          >
            <div slot="cpnj" slot-scope="{ row }">{{ row.empresa.cnpj }}</div>
            <div slot="apelido" slot-scope="{ row }">
              {{ row.empresa.apelido }}
            </div>
            <div slot="razaoSocial" slot-scope="{ row }">
              {{ row.empresa.razaoSocial }}
            </div>
          </data-table>
        </div>
      </template>
    </modal-base>
    <collapsible-card
      :loading="loading"
      colorHeader="#0F214A"
      :itemIndex="itemIndex"
      ref="cardCollapsibleRef"
      @handleIcon="pontoMedicao.opened = !pontoMedicao.opened"
      style="margin-bottom: 20px"
    >
      <template #title>
        <div class="w-100 row fs07 py-1 d-flex align-items-center">
          <div
            v-if="!pontoMedicao.proinfaConfigurado"
            class="col col-3 d-flex align-items-center"
          >
            <b
              class="d-flex align-items-center"
              v-if="!pontoMedicao.hasProinfas"
            >
              <span>{{ getNoProinfaMessage }}</span>
              <img :src="warnIcon" style="margin-left: 10px" />
            </b>
          </div>
          <div class="col col-6">
            <span>
              Ponto:
              <b style="font-weight: bold">{{ pontoMedicao.codigo }}</b>
            </span>
            <span>
              - Retaguarda:
              <b>
                {{ pontoMedicao.retaguarda || "-------------" }}
              </b>
            </span>
            <span v-if="pontoMedicao.contratos.length === 1">
              - Contrato:
              <b style="font-weight: bold">
                {{ pontoMedicao.contratos[0].codigo }}
              </b>
            </span>
          </div>
          <div
            v-if="
              pontoMedicao.contratos.length > 0 &&
              pontoMedicao.contratos.length != 1
            "
            class="col col-3 d-flex justify-content-end"
          >
            <button
              style="padding: 8px; border-radius: 9px; width: 250px"
              class="btn-ver-contratos fs07"
              @click.stop="
                showModalContract = true;
                pontosTable = pontoMedicao;
              "
              data-cy="button-ver_contratos_associardos-proinfa-components"
            >
              <b> Ver Contratos Associados </b>
            </button>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row py-3"><b>PROINFA (MWh)</b></div>
        <table class="table">
          <thead>
            <th class="text-left px-2" style="width: 7%">Ano</th>
            <th
              v-for="(month, i) in MONTHS"
              :key="i"
              class="text-left px-2"
              style="width: 7%"
            >
              {{ month.title }}
            </th>
          </thead>
          <tbody>
            <div class="top-line" />
            <tr v-for="(proinfa, i) in pontoMedicao.proinfas" :key="i">
              <td>
                {{ proinfa.ano }}
              </td>
              <td v-for="(month, j) in MONTHS" :key="`${i}-${j}`">
                <vue-numeric
                  :min="0"
                  :precision="3"
                  placeholder="0,000"
                  class="form-control"
                  decimal-separator=","
                  thousand-separator="."
                  :disabled="IS_DISABLED_FIELD(month, proinfa.ano)"
                  v-model="proinfa[month.name].valor"
                  style="font-size: 0.8em !important"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row d-flex align-items-center justify-content-end">
          <button
            @click="savePontoMedicao(pontoMedicao)"
            class="col col-2 btn btn-primary-full-filled me-4"
            :disabled="!$can('CONFIGURAR_PROINFA_VISUALIZAR_EDITAR')"
            data-cy="button-salvar_contratos_proinfa-proinfa-components"
          >
            Salvar
          </button>
        </div>
      </template>
    </collapsible-card>
  </div>
</template>

<script>
import warnIcon from "@/assets/icons/svg/warn-icon.svg";
import ModalBase from "@/components/modals/ModalBase.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { CONFIRMATION } from "@/constants/strings";
import PontoMedicaoModel from "@/models/PontoMedicaoModel";
import ProinfaService from "@/services/ProinfaService";
import { padLeft } from "@/utils/functionsUtils";
import { DateTime } from "luxon";
import VueNumeric from "vue-numeric";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: undefined,
      warnIcon: warnIcon,
      showModalContract: false,
      pontosTable: new Array(),
    };
  },
  props: {
    itemIndex: Number,
    pontoMedicao: Object,
  },
  computed: {
    ...mapGetters(["getCurrentFaturamento"]),
    PontoMedicaoModel: () => PontoMedicaoModel,
    MONTHS() {
      return PontoMedicaoModel.getTableFields();
    },
    CONTRATO_TABLE_FIELDS() {
      return PontoMedicaoModel.getContratoModalTableFields();
    },
    getNoProinfaMessage() {
      const month = padLeft(DateTime.now().month);
      const year = DateTime.now().year;
      return `Sem PROINFA ${month}/${year}`;
    },
  },
  methods: {
    IS_DISABLED_FIELD({ month }, year) {
      if (!this.$can("CONFIGURAR_PROINFA_VISUALIZAR_EDITAR")) {
        return true;
      }

      const dataReferencia = new Date(
        `${this.getCurrentFaturamento.dataReferencia}T12:00:00`
      );
      const mesReferencia = dataReferencia.getMonth() + 1;
      const anoReferencia = dataReferencia.getFullYear();

      month = Number(month);
      year = Number(year);

      if (
        year < anoReferencia - 1 ||
        (year < anoReferencia && (month !== 12 || mesReferencia !== 1))
      ) {
        return true;
      }

      return month < mesReferencia && year <= anoReferencia;
    },
    getContratoUltimoStatusMessage(contratos = []) {
      if (contratos.length === 0) {
        return this.mxToggleErrorMessageAlert(
          "Nenhum contrato associado ao ponto."
        );
      }
      if (contratos.length === 1) {
        return CONFIRMATION.CALCULO_MEDICOES.RECALCULATE_FATURAMENTO(
          contratos[0].statusAtual.descricao
        );
      }
      return `
          Ao realizar essa ação as seguintes boletas
          serão afetadas/reiniciadas: <b>${contratos
            .map(({ codigo }) => codigo)
            .join(", ")}</b>
        `;
    },
    async savePontoMedicao({ contratos }) {
      const message = this.getContratoUltimoStatusMessage(contratos);
      if (message) {
        try {
          await this.mxToggleWarningModal({ message });
        } catch {
          return;
        }
      }

      const { proinfas, id: idPonto } = this.pontoMedicao.getData();
      this.loading = ProinfaService.updateProinfa({
        idPonto,
        proinfas,
      });
      try {
        await this.loading;
        this.mxToggleToast({ message: CONFIRMATION.PROINFA.UPDATE });
        this.$emit("reload");
      } catch (error) {
        this.mxHandleRequestError(error, "PROINFA");
      }
    },
  },
  components: {
    ModalBase,
    DataTable,
    VueNumeric,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_colors.scss";

.replicate-icon-container {
  height: 100%;
}

.replicate-icon-text {
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 10px;
  color: $color--primary;
}
</style>
