<template>
  <div>
    <modal-base @closeModal="close()" width="fit-content">
      <template #header>Atenção</template>
      <template #body>
        <div class="modal-body">
          <p>Não é possível efetuar a Recompra pois o Spread está expirado.</p>
        </div>
      </template>
      <template #footer>
        <div class="container-buttons">
          <button
            @click="close()"
            class="btn btn-secondary-full-filled btn-cancel"
          >
            Cancelar
          </button>
          <button
            class="btn btn-primary-full-filled"
            @click="navigateToPldSpreadPage()"
          >
            Cadastrar novo Spread
          </button>
        </div>
      </template>
    </modal-base>
  </div>
</template>

<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  methods: {
    close() {
      this.$emit("closeModal");
    },
    navigateToPldSpreadPage() {
      this.$router.push({ path: "/pld-spread" });
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  text-align: center;
  margin-top: 15px;
}

.container-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>
