import CollapsibleCard from "@/components/cards/collapsibleCard.vue";
import DataTable from "@/components/tables/DataTable.vue";
import DynamicTooltip from "@/components/DynamicTooltip.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import Vue from "vue";

const components = [
  ["collapsible-card", CollapsibleCard],
  ["data-table", DataTable],
  ["dynamic-tooltip", DynamicTooltip],
  ["overlay-loader", OverlayLoader],
];

components.map((component) => {
  Vue.component(component[0], component[1]);
});
