<template>
  <div class="_upload-manual">
    <modal-base
      maxWidth="80%"
      v-if="showModalUploadManual"
      data-cy="modal-fechar_modal_upload_manual-modais"
      @closeModal="close"
    >
      <template #header>Realizar Upload Manual</template>
      <template #body>
        <div
          class="_upload-manual__body"
          v-elementAsyncLoader="loading"
          style="position: relative"
        >
          <div>
            Adicionar aqui os arquivos de medição referentes a todos os pontos
            de medição do contrato <b>{{ contrato.codigo }}</b>
          </div>
          <div class="box-upload">
            <div class="item-upload">
              <div style="margin-bottom: 15px" class="mt-3">
                <div><b>Upload de Arquivos</b></div>
                <contrato-upload-manual-field
                  v-elementAsyncLoader="filesLoading"
                  @changeFiles="handleFiles"
                />
              </div>
            </div>
            <div class="item-upload">
              <data-table
                headerStick
                :data="dataContratos"
                :maxItemsToScroll="10"
                :fields="fieldsContratos"
              >
                <div slot="icons" slot-scope="{ row }">
                  <img v-if="row.isValid" :src="icon.check" />
                  <img v-else :src="icon.exclamation" />
                </div>
              </data-table>
            </div>
          </div>
          <data-table
            :stripes="true"
            :data="controleUploadTableData"
            @setPage="setPageControleUpload"
            :pagination="true"
            :lineClickable="false"
            @setPerPage="setPerPageControleUpload"
            :paginationData="controleUploadPagination.paginationData"
            :fields="TableControleUploadModel.getTableFields()"
          >
            <div slot="erro" slot-scope="{ row }">
              <i v-if="row.erro" class="icon icon-stop" />
              <span v-else>--</span>
            </div>
          </data-table>
        </div>
      </template>
      <template #footer>
        <div class="wrapper-buttons">
          <div class="btn-calcular">
            <button
              @click="loadManual"
              :disabled="overlayLoading || !files.length"
              class="btn btn-action-primary"
              data-cy="button-realizar_upload_manual-contratos-"
            >
              Calcular Medições
            </button>
          </div>
        </div>
      </template>
    </modal-base>
    <button
      class="btn btn-action-secondary-outlined"
      @click.self="showModalUploadManual = true"
      :disabled="showModalUploadManual"
      data-cy="button-mostar_modal_upload_manual-contratos-"
    >
      Realizar Upload Manual
    </button>
  </div>
</template>

<script>
import CheckIcon from "@/assets/icons/svg/check.svg";
import DownloadIcon from "@/assets/icons/svg/download_icon.png";
import ExclamationIcon from "@/assets/icons/svg/exclamation.svg";
import TrasherIcon from "@/assets/icons/svg/trasher.png";
import ContratoUploadManualField from "@/components/contratos/ContratoUploadManualField.vue";
import ModalBase from "@/components/modals/ModalBase.vue";
import { CONFIRMATION } from "@/constants/strings";
import TableControleUploadModel from "@/models/tablesModels/TableControleUploadModel";
import TableControleUploadPontoModel from "@/models/tablesModels/TableControleUploadPontoModel";
import ProinfaService from "@/services/ProinfaService";
import UploadService from "@/services/UploadService";
import {
  downloadFileFromResponseObject,
  removeByteOrderMarkFromFiles,
} from "@/utils/functionsUtils";

export default {
  components: {
    ModalBase,
    ContratoUploadManualField,
  },
  props: {
    data: Object,
    contrato: Object,
  },
  data() {
    return {
      controleUploadTableData: [],
      controleUploadPagination: {
        paginationData: undefined,
        filters: {
          page: 0,
          size: 10,
        },
      },
      arquivosEnviados: [],
      overlayLoading: false,
      loading: undefined,
      dataFiles: [],
      filesLoading: undefined,
      dataContratos: [],
      showModalUploadManual: false,
      fieldsFilesLoading: undefined,
      files: [],
      fieldsContratos: [
        { name: "contrato", title: "Contrato" },
        { name: "pontos", title: "Pontos de Medição" },
        { name: "pontosRetaguarda", title: "Pontos de Retaguarda" },
        { name: "icons", title: "" },
      ],
      fieldsFiles: [
        { name: "nome", title: "Nome do Arquivo" },
        { name: "download", title: "" },
        { name: "delete", title: "" },
      ],
    };
  },
  mounted() {
    this.getInicialDataContratos();
    this.updateControleUpload();
  },
  methods: {
    toFormData(files) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("arquivos", file);
      });
      return formData;
    },
    setPageControleUpload(page) {
      this.controleUploadPagination.filters.page = page;
      this.updateControleUpload();
    },
    setPerPageControleUpload(size) {
      this.controleUploadPagination.filters.size = size;
      this.updateControleUpload();
    },
    close() {
      this.arquivosEnviados = [];
      this.dataFiles = [];
      this.getInicialDataContratos();
      this.showModalUploadManual = false;
    },
    getInicialDataContratos() {
      this.loading = ProinfaService.getPontosContrato({
        idContrato: this.contrato.id,
      }).then(({ data }) => (this.dataContratos = data));
    },
    async prevalidateFiles(files) {
      let data;
      try {
        const req = await UploadService.validarArquivosUploadManual({
          files,
          contratoId: this.contrato.id,
        });
        data = req.data;
      } catch (error) {
        this.overlayLoading = false;
        this.mxHandleRequestError(error);
        return;
      }
      const errosArquivos = [];
      for (let i = 0; i < data.length; i++) {
        const arquivo = data[i];
        if (arquivo.validationErrors) {
          const errosArquivo = {
            name: arquivo.nome,
            rows: [],
          };
          for (let j = 0; j < arquivo.validationErrors.length; j++) {
            const erro = arquivo.validationErrors[j];
            errosArquivo.rows.push({ row: erro.linha, errors: erro });
          }
          if (errosArquivo.rows.length) {
            errosArquivos.push(errosArquivo);
          }
        }
      }
      if (errosArquivos.length) {
        this.mxToggleFilesError(errosArquivos);
        throw new Error("Arquivos inválidos");
      }
    },
    async handleFiles(payload = new Array()) {
      this.overlayLoading = true;
      this.files = await removeByteOrderMarkFromFiles([...payload]);

      this.filesLoading = this.prevalidateFiles(this.toFormData(this.files));
      try {
        await this.filesLoading;
      } finally {
        this.overlayLoading = false;
      }
    },
    async loadManual() {
      this.filesLoading = UploadService.uploadManual({
        idContrato: this.contrato.id,
        arquivos: this.toFormData(this.files),
      });
      try {
        await this.filesLoading;
        this.mxToggleToast({
          message: CONFIRMATION.CALCULO_MEDICOES.CREATED,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        this.overlayLoading = false;
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 422) {
            this.mxToggleErrorMessageAlert(response.data.message);
          }
        } else {
          this.mxHandleRequestError(error);
        }
        return;
      }
    },
    downloadArquivo(idHistoricoAquivo, fileName) {
      this.fieldsFilesLoading = UploadService.downloadArquivo({
        idHistoricoAquivo,
      })
        .then((response) => {
          downloadFileFromResponseObject(response, fileName);
          this.mxToggleToast({
            message: CONFIRMATION.UPLOAD_MANUAL.DOWNLOAD,
          });
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    excluirArquivo(idHistoricoAquivo) {
      this.fieldsFilesLoading = UploadService.deletarArquivo({
        idHistoricoAquivo,
      })
        .then(({ data }) => {
          const fileIndex = this.dataFiles.findIndex(
            (file) => file.idHistoricoAquivo == idHistoricoAquivo
          );

          if (fileIndex > -1) {
            this.dataContratos.map((contrato, index) => {
              const ponto = data.find(
                (namePonto) => contrato.pontos === namePonto
              );

              if (ponto) {
                this.dataContratos[index].isValid = false;
              }
            });

            this.dataFiles.splice(fileIndex, 1);
          }

          this.mxToggleToast({
            message: CONFIRMATION.UPLOAD_MANUAL.DELETE,
          });
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    async updateControleUpload() {
      const { data } = await UploadService.getControleUploadById({
        ...this.controleUploadPagination.filters,
        id: this.contrato.id,
      });
      this.controleUploadTableData = data.content.flatMap((arquivo) =>
        arquivo.pontos.map(
          (ponto) =>
            new TableControleUploadModel({
              ...arquivo,
              ponto: new TableControleUploadPontoModel(ponto),
            })
        )
      );
      this.controleUploadPagination.paginationData = data;
    },
  },
  computed: {
    IS_DISABLE_FINISH() {
      const isValidAllPoints = this.dataContratos.every(
        ({ isValid }) => isValid
      );
      return !this.dataFiles.length || !isValidAllPoints;
    },
    icon() {
      return {
        download: DownloadIcon,
        trasher: TrasherIcon,
        check: CheckIcon,
        exclamation: ExclamationIcon,
      };
    },
    TableControleUploadModel: () => TableControleUploadModel,
  },
};
</script>

<style lang="scss" scoped>
._upload-manual {
  &__body {
    width: 100%;
    display: flex;
    font-size: 1rem;
    color: #2a2a2a;
    line-height: 29px;
    padding: 20px 30px;
    flex-direction: column;
  }
}

.box-upload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.item-upload {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
}

.wrapper-buttons {
  width: 100%;
  display: flex;
  padding: 0 30px;
  text-align: right;
  justify-content: flex-end;
}
.btn-calcular {
  width: 300px !important;
}
</style>
