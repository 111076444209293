<template>
  <div class="cards-container">
    <modal-sincronizar-divergencias
      v-if="showModalSincronizarDivergencias"
      @close="showModalSincronizarDivergencias = false"
      @sincronizarDivergenciasWbc="sincronizar"
    />
    <collapsible-card :itemIndex="itemIndex" @handleIcon="handleIcon">
      <template #title>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img :src="arrowDown" v-if="!contrato.opened" class="me-3" />
            <img :src="arrowUp" v-else class="me-3" />
            <div class="me-3">
              Contrato:
              <span class="cards-container-contratos">{{
                contrato.codigo | EMPTY
              }}</span>
            </div>
            <div class="me-3">
              Referência:
              <span class="cards-container-contratos">
                {{ contrato.codigoContratoReferencia | EMPTY }}
              </span>
            </div>
          </div>
          <div>
            <button
              class="btn btn-sincronizar-informacoes fs07"
              @click.stop="showModalSincronizarDivergencias = true"
            >
              Sincronizar Informações
            </button>
          </div>
        </div>
      </template>
      <template #body>
        <table class="w-100">
          <thead>
            <tr>
              <th style="width: 35%">Razão Social</th>
              <th style="width: 30%">Apelido</th>
              <th style="width: 20%">CNPJ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ RAZAO_SOCIAL }}</td>
              <td>{{ APELIDO }}</td>
              <td>{{ CNPJ }}</td>
            </tr>
          </tbody>
        </table>
        <div class="mt-5 mb-3">
          <b> Inconsistências: </b>
        </div>
        <card-base
          v-for="(
            { nome, valorBilling, valorWbc }, inconsistenciaIndex
          ) in contrato.campos"
          :key="`inconsistencia_card_${inconsistenciaIndex}`"
        >
          <template #header> {{ nome }} </template>
          <template #body>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div style="min-width: 10%">
                  <b>Billing</b>
                </div>
                <div style="min-width: 10%">
                  <b>WBC</b>
                </div>
              </div>
              <div class="d-flex">
                <div style="min-width: 10%">{{ valorBilling | EMPTY }}</div>
                <div style="min-width: 10%">{{ valorWbc | EMPTY }}</div>
              </div>
            </div>
          </template>
        </card-base>
      </template>
    </collapsible-card>
  </div>
</template>

<script>
import arrowDownLight from "@/assets/icons/svg/arrow-down-light.svg";
import arrowUp from "@/assets/icons/svg/arrow-up.svg";
import CardBase from "@/components/cards/cardBase.vue";
import { CNPJ, EMPTY } from "@/filters/formatters";
import ModalSincronizarDivergencias from "./ModalSincronizarDivergencias.vue";

export default {
  props: {
    contrato: Object,
    itemIndex: Number,
  },
  data() {
    return {
      arrowUp: arrowUp,
      arrowDown: arrowDownLight,
      showModalSincronizarDivergencias: false,
    };
  },
  computed: {
    RAZAO_SOCIAL() {
      return this.contrato.empresa
        ? EMPTY(this.contrato.empresa.razaoSocial)
        : "--";
    },
    APELIDO() {
      return this.contrato.empresa
        ? EMPTY(this.contrato.empresa.apelido)
        : "--";
    },
    CNPJ() {
      return this.contrato.empresa ? CNPJ(this.contrato.empresa.cnpj) : "--";
    },
  },
  methods: {
    handleIcon() {
      this.$emit("handleIcon");
    },
    sincronizar() {
      if (
        this.contrato.campos[3] &&
        this.contrato.campos[3].valorWbc &&
        this.contrato.campos[3].valorWbc === "FILHA"
      ) {
        const codigoMae = Number(this.contrato.campos[0].valorWbc);
        this.$emit("sincronizar", codigoMae);
      } else {
        const codigo = Number(this.contrato.codigoContratoReferencia);
        this.$emit("sincronizar", codigo);
      }
      this.showModalSincronizarDivergencias = false;
    },
  },
  filters: {
    CNPJ,
    EMPTY,
  },
  components: {
    CardBase,
    ModalSincronizarDivergencias,
  },
};
</script>

<style lang="scss" scoped>
.cards-container {
  padding: 30px;
  padding-bottom: 0px;
}

.cards-container-contratos {
  font-weight: 700;
}

.btn-sincronizar-informacoes,
.btn-sincronizar-informacoes:active,
.btn-sincronizar-informacoes:visited,
.btn-sincronizar-informacoes:focus {
  height: 34px;
  font-size: 12px;
  width: 294.52px;
  border-radius: 9px;
  color: $color--blue-btn !important;
  background: $color--primary-white !important;
  border: 1px solid $color--primary-white !important;

  &:hover {
    color: $color--primary-white !important;
    background: $color--blue-btn !important;
    border: 1px solid $color--primary-white !important;
  }
}
</style>