import { EMPTY } from "@/filters/formatters";
import TableControleUploadPontoModel from "@/models/tablesModels/TableControleUploadPontoModel";

export default class TableControleUploadModel {
  constructor({
    id,
    arquivoCloudStorage,
    dataIniciado,
    status,
    erro,
    responsavel,
    ponto,
    detalhe,
  } = {}) {
    this._id = id;
    this._arquivoCloudStorage = arquivoCloudStorage;
    this._dataIniciado = dataIniciado;
    this._status = status;
    this._erro = erro;
    this._responsavel = responsavel;
    this._ponto = ponto;
    this._detalhe = detalhe;
  }

  get id() {
    return this._id;
  }

  set id(id) {
    return (this._id = id);
  }

  get arquivoCloudStorage() {
    return this._arquivoCloudStorage;
  }

  set arquivoCloudStorage(arquivoCloudStorage) {
    return (this._arquivoCloudStorage = arquivoCloudStorage);
  }

  get dataIniciado() {
    return this._dataIniciado;
  }

  set dataIniciado(dataIniciado) {
    return (this._dataIniciado = dataIniciado);
  }

  get status() {
    return this._status;
  }

  set status(status) {
    return (this._status = status);
  }

  get erro() {
    return this._erro;
  }

  set erro(erro) {
    return (this._erro = erro);
  }

  get responsavel() {
    return this._responsavel;
  }

  set responsavel(responsavel) {
    return (this._responsavel = responsavel);
  }

  get ponto() {
    return this._ponto;
  }

  set ponto(ponto = new TableControleUploadPontoModel()) {
    return (this._ponto = ponto);
  }

  get detalhe() {
    return this._detalhe;
  }

  set detalhe(detalhe) {
    return (this._detalhe = detalhe);
  }

  static getTableFields() {
    return new Array(
      {
        name: "arquivoCloudStorage",
        title: "Nome do Arquivo",
        width: "20%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "ponto.codigoPonto",
        title: "Ponto de Medição",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "status",
        title: "Status do Upload",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "erro",
        title: "Erro no Upload",
        width: "5%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "detalhe",
        title: "Detalhe do Upload",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "ponto.status",
        title: "Status do Upload",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      },
      {
        name: "ponto.detalhe",
        title: "Detalhe do Ponto",
        width: "15%",
        formatter(value) {
          return EMPTY(value);
        },
      }
    );
  }
}
